import React, { useEffect, useRef, useState } from "react";
import "components/HamburgerMenu/HamburgerMenu.scss"; // Import CSS file for styling
import { NavLink } from "react-router-dom";
import { HiMagnifyingGlass } from "react-icons/hi2";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="hamburger_menu" ref={menuRef}>
      <div
        className={isOpen ? "hamburger_menu_icon open" : "hamburger_menu_icon"}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={isOpen ? "menu_items open" : "menu_items"}>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/perfumes">
            PERFUMES
          </NavLink>
        </li>
        {/* <li>
          <NavLink onClick={() => setIsOpen(false)} to="/clothing">
            CLOTHING
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/bags">
            BAGS
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/jewellery">
            JEWELLERY
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/accessories">
            ACCESSORIES
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/decor">
            DECOR
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/designers">
            DESIGNERS
          </NavLink>
        </li> */}
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/blogs">
            STORIES
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => setIsOpen(false)} to="/about">
            ABOUT
          </NavLink>
        </li>
        {/* DEVIDER */}
        {/* <span className="devider_line" />
            <li>
              <NavLink to="/">STORIES</NavLink>
            </li>
            <li>
              <NavLink to="/">EDITORIAL</NavLink>
            </li>
            <li>
              <NavLink to="/">OBJECT</NavLink>
            </li>
            <li>
              <NavLink to="/">ABOUT</NavLink>
            </li> */}
        {/* <li>
          <a>
            <HiMagnifyingGlass fontSize={20} style={{ marginTop: "5px" }} />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default HamburgerMenu;
