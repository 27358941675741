import React from "react";
import { Link, useParams } from "react-router-dom";
import "pages/blogs/blog.scss";

import { useSelector } from "react-redux";
import MainBlocks from "pages/blogs/mainBlocks";
import { CloseFullscreen } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const Blog = () => {
  const { id, category } = useParams();
  const itemsFiltered = {};
  const { blocks, categories } = useSelector((state) => state.blogs);

  //GETTING ITEMS SAME AS ON BLOGS PAGE BECAUSE WE NEED TO FILTER THEM AGAIN IF WE OPENED BLOG FROM HOME PAGE
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (!itemsFiltered[category]) {
      itemsFiltered[category] = [];
    }
    itemsFiltered[category].push(block);
  }
  const otherBlogs = categories.map((category) => {
    return {
      ...category,
      blocks: itemsFiltered[category.title],
    };
  });

  const editorialItem = blocks.find((item) => {
    return item.id.toLowerCase() === id;
  });
  const suggestedBlogs = otherBlogs?.filter((blog) => {
    return blog.title.toLowerCase() === category.toLowerCase();
  });

  const isVideo = editorialItem?.mainVideoUrl;
  const [firstSentence, rest] = splitFirstSentence(editorialItem?.description);

  function splitFirstSentence(inputString) {
    // Find the index of the first period (.)
    const periodIndex = inputString.indexOf(".");

    // If a period is found, split the string into two parts
    if (periodIndex !== -1) {
      const firstSentence = inputString.substring(0, periodIndex + 1).trim();
      const rest = inputString.substring(periodIndex + 1).trim();

      return [firstSentence, rest];
    }

    // If no period is found, consider the entire string as the first sentence
    return [inputString.trim(), ""];
  }

  //   VIDEO BLOCK
  const renderVideoBlock = () => {
    return (
      <>
        <div className="video_block_container">
          <iframe
            className="video_iframe"
            allow="autoplay"
            frameborder="0"
            src={editorialItem?.mainVideoUrl || ""}
          ></iframe>
          {/* RENDERRING BLOCKS WITH SUGGESTIONS */}
        </div>
        <div className="suggested_blogs_container">
          {suggestedBlogs?.map((item) => {
            if (item.id === id) return null;
            return (
              <div className="suggested_blogs">
                <MainBlocks noTitle small blog={item} id={id} />
              </div>
            );
          })}
        </div>
      </>
    );
  };
  //   IMAGE BLOCK
  const renderImageBLock = () => {
    return (
      <div className="image_block_container">
        {editorialItem?.mainPhotos?.map((photo) => {
          if (photo?.img1 && (photo?.img2 || photo?.img2 === null)) {
            return (
              <div className="double_image_block">
                <img src={photo?.img1} alt={photo?.img1} />
                {photo?.img2 ? (
                  <img src={photo?.img2} alt={photo?.img2} />
                ) : (
                  <div />
                )}
              </div>
            );
          } else if (photo?.img1 && photo?.img2 == undefined) {
            return (
              <div className="image_block">
                <img src={photo?.img1} alt={photo?.img1} />
              </div>
            );
          }
        })}
      </div>
    );
  };
  // const specificUrl = ["stories", "HIDDEN WORLD", "1511 GUESTS"];
  const link = editorialItem?.shopUrl?.includes("/blog-collage")
    ? `${editorialItem?.shopUrl}/${id}`
    : editorialItem?.shopUrl;

  return (
    <div className="blog_item_container">
      <Helmet>
        <title>1511 Paris | {editorialItem?.title}</title>
        {/* <link
          rel="canonical"
          href={`https://1511.paris/blogs/${editorialItem?.category}/${editorialItem?.id}`}
        /> */}
        <meta
          name="keywords"
          content={`${
            editorialItem?.title
          }, ${editorialItem?.title.toLowerCase()}, Stories, Editorials, Objects, Design, Designers, Creativity, Innovation, Inspirations, Journeys, Trends, Aesthetics, Functionality, Articles, Videos, Captivating, Inspiring, Thought-Provoking, Transformative, Exploration, Planets, Poetry, Documentary, Film, Photography, Art, Design, 1511 stories, 1511 editorials, 1511 objects`}
        />
        <meta
          name="description"
          content={`${editorialItem?.description || editorialItem?.title}`}
        />
        <meta
          property="og:description"
          content={`${editorialItem?.description || editorialItem?.title} `}
        ></meta>
      </Helmet>
      <div className="blog_item_title">
        <h2>{editorialItem?.title}</h2>
        <p>{editorialItem?.description}</p>
        {/* <span>{rest}</span> */}
        {/* <Link to={link}>Shop {category}</Link> */}
      </div>
      {/* VIDEO OR PHOTO BLOCK */}
      {isVideo && renderVideoBlock()}
      {!isVideo && renderImageBLock()}
    </div>
  );
};

export default Blog;
