import React, { useRef, useEffect } from "react";
import { useLocation, Outlet, NavLink } from "react-router-dom";
import "components/Navbar/NavBar.scss";
import { BsStar } from "react-icons/bs";
import MainLogo from "assets/new_logo.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ShoppingBag } from "assets/shoppingBag.svg";
import CartModal from "components/Reusable/Cart/Cart";
import { SET_CART_OPEN } from "store/slices/CartSlice";
import PersonIcon from "assets/personIcon";
import HamburgerMenu from "components/HamburgerMenu/HamburgerMenu";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartOpen, products } = useSelector((state) => state.cart);
  const { accessToken, role } = useSelector((state) => state.user);
  const containerRef = useRef(null);

  //WHEN TO DISPLAY ADMIN PANEL BTN
  const displayAdminPanel = useLocation().pathname.includes("/adminpanel")
    ? "none"
    : "block";

  //CART MODAL OPEN/CLOSE
  const handleCartOpen = () => {
    if (cartOpen) {
      dispatch(SET_CART_OPEN(false));
    } else {
      dispatch(SET_CART_OPEN(true));
    }
  };

  //LISTEN FOR CLICK OUTSIDE OF CART MODAL TO CLOSE IT
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        cartOpen
      ) {
        dispatch(SET_CART_OPEN(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, cartOpen]);

  //SIGN IN/OUT
  const handleSign = () => {
    if (accessToken) {
      navigate("/profile");
    } else {
      navigate("/signin");
    }
  };
  return (
    <>
      <div className="navbar_container">
        <HamburgerMenu />
        <div className="main_logo">
          <NavLink
            onClick={() => {
              localStorage.setItem("page", JSON.stringify(1));
              localStorage.removeItem("productScrollRef");
            }}
            to="/"
          >
            <img src={MainLogo} />
          </NavLink>
        </div>
        <nav className="nav_menu_container">
          <ul className="nav_menu">
            <li>
              <NavLink to="/perfumes">PERFUMES</NavLink>
            </li>
            {/* <li>
              <NavLink to="/clothing">CLOTHING</NavLink>
            </li>
            <li>
              <NavLink to="/bags">BAGS</NavLink>
            </li>
            <li>
              <NavLink to="/jewellery">JEWELLERY</NavLink>
            </li>
            <li>
              <NavLink to="/accessories">ACCESSORIES</NavLink>
            </li>
            <li>
              <NavLink to="/decor">DECOR</NavLink>
            </li>
            <li>
              <NavLink to="/vintage">VINTAGE</NavLink>
            </li>
            <li>
              <NavLink to="/designers">DESIGNERS</NavLink>
            </li> */}
            {/* DEVIDER */}
            {/* <span className="devider_line" /> */}
            <li>
              <NavLink to="/blogs">STORIES</NavLink>
            </li>
            <li>
              <NavLink to="/about">ABOUT</NavLink>
            </li>
            {/* <li>
              <NavLink to="/">EDITORIAL</NavLink>
            </li>
            <li>
              <NavLink to="/">OBJECT</NavLink>
            </li>
             */}
            {/* <li>
              <a>
                <HiMagnifyingGlass fontSize={20} style={{ marginTop: "5px" }} />
              </a>
            </li> */}
          </ul>
          {role && role === "admin" && (
            <button
              onClick={() => navigate("/adminpanel")}
              type="button"
              className="btn btn-outline-primary ml-5 btn-sm admin_btn"
              style={{
                display: displayAdminPanel,
              }}
            >
              {" "}
              Admin panel
            </button>
          )}
        </nav>
        <div ref={containerRef} className="right_menu_nav">
          <span className="sign_in_icon" onClick={handleSign}>
            <PersonIcon className="person_icon" />
          </span>
          <span
            onClick={() => navigate("/profile/wishList")}
            className="shopping_bag_favorite"
          >
            <BsStar className="bs_star_icon" />
          </span>
          <span onClick={handleCartOpen} className="shopping_bag_element">
            <ShoppingBag className="shopping_bag_icon" />
            <span className="bag_count_element">
              <p>{products?.length}</p>
            </span>
          </span>
          {cartOpen ? <CartModal /> : null}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default NavBar;
