import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DELETE_CART_ITEM } from "store/slices/CartSlice";
import "styles/ShoppingBag.scss";
import { cartToasts, cartErrorToasts } from "utils/toasts";
import { useLazyCheckoutQuery } from "api/cart";
import useLocalStorage from "utils/localStorageHelper";
import { useDeleteOrderMutation } from "api/orders/index";
import WebTable from "components/ShoppingBag/WebTable";
import MobileTable from "components/ShoppingBag/MobileTable";
import useWindowDimensions from "utils/windowWidth";
import TransportRestrictions from "components/Modals/TransportRestrictions";

const ShoppingBag = () => {
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.cart);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [value, setValue] = useLocalStorage("cart", { products: [] });
  //Transportation restrictions consent modal
  const [
    transportationRestrictionsConsent,
    setTransportationRestrictionsConsent,
  ] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //* ------------------- *//

  const { search } = useLocation();
  const isCancelledCheckout = search
    ?.substring(0)
    ?.split("?")[1]
    ?.includes("id");
  const [
    deleteOrder,
    { data: deleteOrderData, error: errorDeletingOrder, isError, isSuccess },
  ] = useDeleteOrderMutation();
  const orderId = search?.split("&orderId=")[1];

  useEffect(() => {
    if (isCancelledCheckout && orderId) {
      deleteOrder(orderId);
      cartErrorToasts("Checkout cancelled");
    }
  }, [isCancelledCheckout]);

  const dispatch = useDispatch();
  const [
    checkout,
    { data, isLoading, isError: isCheckoutError, error: checkoutError },
  ] = useLazyCheckoutQuery();

  useEffect(() => {
    if (products?.length > 0) {
      setSubTotal(
        products.reduce(
          (acc, product) => acc + product.priceHT * product.quantity,
          0
        )
      );
      setTaxes(
        products.reduce(
          (acc, product) =>
            acc +
            ((product.finalPrice * product.quantity) /
              Number(`1.${product.vat}`)) *
              Number(`0.${product.vat}`),
          0
        )
      );
    }
  }, [products]);

  useEffect(() => {
    setValue({ products });
  }, [products]);

  //If checkout is successful, redirect to the payment page
  useEffect(() => {
    if (data && !isLoading && !isCheckoutError) {
      window.location.href = data?.url;
    }
  }, [data]);

  const deleteCartItem = (product) => {
    dispatch(DELETE_CART_ITEM(product));
    cartToasts("Removed from cart");
  };
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  //CHeckout errors
  const isCheckoutStockError = checkoutError?.data?.dbStockError?.length > 0;
  const dbItemsOutOfStock = checkoutError?.data?.dbStockError;

  useEffect(() => {
    if (isCheckoutStockError) {
      cartErrorToasts("Some items are out of stock", 3000);
    }
  }, [isCheckoutStockError]);

  return (
    <div className="shopping_bag_container">
      <h1 className="shopping_bag_heading">Shopping Bag</h1>
      {products?.length > 0 ? (
        <div className="shopping_bag_wrapper">
          <h2>Summary</h2>
          <div className="shopping_bag_summary" style={{ userSelect: "none" }}>
            {!isMobile ? (
              <WebTable
                dbItemsOutOfStock={dbItemsOutOfStock}
                products={products}
                deleteCartItem={deleteCartItem}
                subTotal={subTotal}
                taxes={taxes}
              />
            ) : (
              <MobileTable
                dbItemsOutOfStock={dbItemsOutOfStock}
                products={products}
                deleteCartItem={deleteCartItem}
                subTotal={subTotal}
                taxes={taxes}
              />
            )}
          </div>
          <div className="shopping_bag_action_buttons">
            <button onClick={() => navigate("/")}>Continue Shopping</button>
            <button
              onClick={() => {
                //CHECKOUT
                if (!transportationRestrictionsConsent) {
                  setIsModalOpen(true);
                  return;
                } else {
                  checkout({
                    products: products?.map((item) => ({
                      id: item?._id.toString(), //because _id is an objectId
                      quantity: item?.quantity,
                      size: item?.selectedSize,
                      color: item?.selectedColor,
                      fragrances: item?.fragrances,
                    })),
                  });
                }
              }}
            >
              Checkout
            </button>
          </div>
        </div>
      ) : (
        <div className="shopping_bag_empty"> Your shopping bag is empty </div>
      )}
      <TransportRestrictions
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        transportationRestrictionsConsent={transportationRestrictionsConsent}
        setTransportationRestrictionsConsent={
          setTransportationRestrictionsConsent
        }
        checkout={() =>
          checkout({
            products: products?.map((item) => ({
              id: item?._id.toString(), //because _id is an objectId
              quantity: item?.quantity,
              size: item?.selectedSize,
              color: item?.selectedColor,
              fragrances: item?.fragrances,
            })),
          })
        }
      />
    </div>
  );
};

export default ShoppingBag;
