import { useState } from "react";
import { ReactComponent as Star } from "assets/star.svg";
import "components/Reusable/ProductCard/productCard.scss";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useWishList } from "utils/hooks/useWishList";
import CustomLoader from "components/Reusable/CustomLoader/CustomLoader";
import useWindowDimensions from "utils/windowWidth";
import Modal from "components/Reusable/Modal/Modal";
import AdminWishlistModal from "components/AdminWishlistModal/AdminWishlistModal";
import useNavigateToAdminPanel from "utils/hooks/useNavigateToAdminPanel";

const ProductCard = ({ item, thePassedId, cardSize = "500px" }) => {
  let { category } = useParams();
  const { width } = useWindowDimensions();
  const user = JSON.parse(localStorage.getItem("user"));
  const { isInWishList, handleStarClick, isLoading } = useWishList(item);
  const { navigateToAdminPanel } = useNavigateToAdminPanel(item);
  const [showInAdminPanel, setShowInAdminPanel] = useState(false);
  const isSoldOut =
    item?.size?.filter((size) => size.quantity > 0).length === 0;
  const isPerfume = item?.category === "Perfumes";

  const handleItemClick = () => {
    const itemCategory = item?.category.toLowerCase();
    if (category) {
      return `/product/${category}/${item?.id}`;
    } else if (!category && item?.parentCategory === item.category) {
      return `/product/${itemCategory}/${item?.id}`;
    } else if (!category && !item?.parentCategory) {
      return `/product/${itemCategory}/${item?.id}`;
    } else {
      return `/product/${item?.parentCategory}/${item.category}/${item?.id}`;
    }
  };

  const [open, setOpen] = useState(false);
  const handleStarClickWrapper = () => {
    if (user.role === "admin") {
      if (!open) {
        setOpen(true);
      }
    } else {
      handleStarClick();
      return;
    }
  };

  return (
    <div
      onMouseOver={() => user.role === "admin" && setShowInAdminPanel(true)}
      onMouseLeave={() => setShowInAdminPanel(false)}
      className="whatsnew_product_wrapper"
      style={{ position: "relative", maxWidth: cardSize }}
    >
      <Modal open={open} handleClose={() => setOpen(false)}>
        {/* Should be wrapped in a fragment! */}
        <>
          <AdminWishlistModal item={item} />
        </>
      </Modal>
      <Link
        onClick={(e) => {
          e.stopPropagation();
          thePassedId(item?.id);
        }}
        to={`${handleItemClick()}`}
        state={{ item: item }}
        className="whatsnew_product_btn"
      >
        {/* IMAGE BLOCK */}
        <div className="whatsnew_product_image_container">
          {/* EXCLUSIVE */}
          {item?.exclusive ? (
            <div className="whatsnew_product_exclusive_wrapper">
              <div className="whatsnew_product_exclusive_triangle"></div>
              <div className="whatsnew_product_exclusive_text">
                <p>Exclusive</p>
              </div>
            </div>
          ) : null}
          {/* IMAGE */}
          <div>
            <img
              loading="lazy"
              className="whatsnew_product_image"
              src={item?.primaryPhoto || item.photos[0].url}
              alt={item?.photos[0].alt}
            ></img>
          </div>
          {/* SOLD OUT */}
          {isSoldOut && isPerfume ? (
            <div className="whatsnew_product_soldout">
              <p>PLACE ORDER</p>
            </div>
          ) : isSoldOut ? (
            <div className="whatsnew_product_soldout">
              <p>SOLD OUT</p>
            </div>
          ) : null}
        </div>
        {/* TEXT BLOCK */}
        <p className="product_card_title" style={{ margin: "10px 0 0 0" }}>
          {item?.designer.toLowerCase()}
        </p>
        <p className="product_card_designer" style={{ fontWeight: "400" }}>
          {item?.shortDescription}
        </p>
        <p className="product_card_price">
          {item.finalPrice.format(0, 3, " ", ",")} €
        </p>
      </Link>
      {showInAdminPanel && width > 768 ? (
        <div
          style={{
            position: "absolute",
            bottom: "125px",
            right: "10px",
            zIndex: 10000,
          }}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigateToAdminPanel(e);
            }}
            variant="contained"
          >
            Show in admin panel
          </Button>
        </div>
      ) : null}
      {/* STAR */}
      {isLoading ? (
        <CustomLoader />
      ) : (
        <span onClick={handleStarClickWrapper}>
          <Star
            fill={isInWishList ? "#000" : "#fff"}
            stroke="rgba(0, 0, 0, 0.5)"
            className="whatsnew_product_star"
          />
        </span>
      )}
    </div>
  );
};

export default ProductCard;
