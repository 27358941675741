import "styles/Modals/TransportRestrictions.scss";
import Modal from "components/Reusable/Modal/Modal";

const TransportRestrictions = ({
  isModalOpen,
  setIsModalOpen,
  transportationRestrictionsConsent,
  setTransportationRestrictionsConsent,
  checkout,
}) => {
  return (
    <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
      {/* customer message cannot ship flamable objects by air */}
      <div className="shopping_bag_modal">
        <div className="shopping_bag_modal_wrapper">
          <h2>Dear customer,</h2>
          <p>
            Due to transport restrictions, we are unable to ship pressurized or
            flammable items by air and, as a result, these products must be
            delivered via standard shipping options with extended estimated
            times for arrival.
          </p>
          <p>
            Please also note that as we are unable to deliver to certain
            regions, we suggest that you select a different shipping
            destination, namely in Europe, or contact our team for further
            assistance
          </p>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="transportationRestrictionsConsent"
              checked={transportationRestrictionsConsent}
              onChange={(e) =>
                setTransportationRestrictionsConsent(e.target.checked)
              }
            />
            <label
              className="form-check-label"
              htmlFor="transportationRestrictionsConsent"
            >
              I understand and agree to the transportation restrictions
            </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <button className="modal_button_warning">
              <a
                href="mailto:advisor@1511.paris"
                onClick={() => setIsModalOpen(false)}
              >
                Get in touch with our team
              </a>
            </button>
            <button
              onClick={() => {
                if (transportationRestrictionsConsent) {
                  checkout();
                }
              }}
              className="modal_button_warning"
            >
              Chekout
            </button>
          </div>
          {/* close button right top corner */}
          <button
            className="modal_close_button"
            onClick={() => setIsModalOpen(false)}
          >
            X
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransportRestrictions;
