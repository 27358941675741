import React, { useEffect, useState } from "react";
import ProductCard from "components/Reusable/ProductCard/ProductCard";
import {
  useLazyGetCategoryProductsQuery,
  useLazyGetDesignerProductsQuery,
} from "api/products/";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "components/Reusable/BreadCrumbs/BreadCrumb";
import "styles/CataloguePage.scss";

import Filters from "components/Reusable/Filters/Filters";
import { Pagination } from "@mui/material";
import Modal from "components/Reusable/FilterDrawer/FilterDrawer";
import useWindowDimensions from "utils/windowWidth";
import { Helmet } from "react-helmet";
import { useGetDesignersQuery } from "api/designers";

const CataloguePage = () => {
  let { category, subCategory, designer } = useParams();
  const [page, setPage] = useState(1);
  const [locationState, setLocationState] = useState(useLocation()?.state);

  const [getProducts, { data: products, isLoading, isError, error }] =
    useLazyGetCategoryProductsQuery();

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [queryString, setQueryString] = useState({});

  useEffect(() => {
    setFilteredProducts(products);
  }, [products, subCategory, category]);

  useEffect(() => {
    //FETCHING NEW PRODUCTS BASED ON PAGE NUMBER AND QUERY STRING
    //IF WE HAVE QUERIES SET IN THE URL WE WILL FETCH PRODUCTS WITH FILTERS
    //ELSE WE WILL FETCH PRODUCTS WITHOUT FILTERS

    if (Object.keys(queryString).length > 0) {
      getProducts({
        category,
        subCategory,
        page: page,
        filter: JSON.stringify(queryString),
        designer,
      });
    } else {
      getProducts(
        subCategory
          ? { category, subCategory, page, designer }
          : { category, page, designer }
      );
    }
  }, [page, category]);

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  //SCROLL TO TOP ON PAGE CHANGE
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  }, [page]);

  //If we coming to the catalogue page and we have designer in the url we need to load designer info
  const { data: designers } = useGetDesignersQuery();
  if (designer && designers?.length > 0 && !locationState) {
    setLocationState({
      designerInfo: designers?.filter((item) => item?.name === designer)[0],
    });
  }

  if (error) {
    return (
      <div className="error_container">
        <h1>{category ? category?.toUpperCase() : "No products found"}</h1>
        <div className="error_message">
          <h2>{error?.data?.error}</h2>
        </div>
      </div>
    );
  }
  const keywordsPerCategory = {
    perfumes:
      "1511 Perfume, 1511 perfume, perfume, Perfume, Perfume Paris, perfume online, exclusive perfume, Planets, planets, hand-made perfume, Haute perfumery, Extrait de parfum, Luxury perfume, Glass bottles, Refill, Floral, Spicy, Woody, Leather, Oriental, Fruity, Rose Berries, Bergamot, Siam, Vanilla, Black pepper, Vetiver, Oud wood, Elemi, Aldehyde, Rose, Cypriol, Cardamom, Vanilla, Amber Accord, Incense, Cyclamen, Tuberose, Ylang-Ylang, Patchouli, White Musk, Dill, Cypress, Ginger, Violet, Geranium, Tonka bean, Sandalwood, Vetiver, Ravensara, Iris, Violet, Tonka Bean, Tobacco, Cedar, Olibanum, Nutmeg, Saffron, Immortelle, Rose, Gaïac wood, Wine Lees, Iris, Mimosa, Aldehyde, Iris, Jasmine, Labdanum, Hyraceum, Cinnamon, Cocoa, Styrax, Almond, Lemon, Bigarade, Honey, Berry Accord, Liatrix, Grapefruit",
    clothing:
      "1511 Clothing, Clothing, Shop, clothing, clothing paris, Clothing Paris, clothing Paris, Paris clothing, Paris, Fashion, Apparel, Dresses, Tops, Bottoms, Shirts, Pants, Jeans, Skirts, Jackets, Coats, Sweaters, Cardigans, Blouses, T-shirts, Activewear, Swimwear, Lingerie, Loungewear, Formalwear, Casualwear, Designer Clothing, Trendy Fashion, Seasonal Fashion, Plus Size Clothing, Sustainable Fashion, Fashion Accessories, Fashion Trends, Style Inspiration, ",
    bags: "1511 Bags, Bags, Shop, bags, bags paris, Bags Paris, bags Paris, Paris bags, Paris, Fashion, Apparel, Clutch bag, clutch bags, cluthes, cluthes bags, cluthes bags paris, cluthes bags Paris, cluthes bags Paris, Paris cluthes bags, Paris, Fashion, Apparel, Handbags, Tote Bags, Shoulder Bags, Crossbody Bags, Backpacks, Clutch Bags, Pouches, Belt Bags, Top Handle Bags, Satchels, Designer Bags, Trendy Fashion, Seasonal Fashion, Sustainable Fashion, Fashion Accessories, Fashion Trends, Style Inspiration",
    jewellery:
      "1511 Jewellery, 1511 jewellery, jewellery, jewelry, Unique, Craftsmanship, Necklaces, Brooches, Fashion Jewelry, necklace, earrings, cuff, watch, watches,Silver Jewelry, Gold Jewelry, gold, pearl, Pearls, Women's Jewelry, jewelry Paris, unique jewellery Paris, unique jewellery,",
    accessories:
      "1511 Craftsmanship, scarf, belt, cape, sunglasses, headpiece, satin, cap, tie, swarovski, swarovski crystals, tie-up, glove, gloves, leather, leather gloves, glove, paris, Paris, Fashion Accessories, Women's Accessories ",
    decor:
      "1511 Decor, 1511 decor, decor, home decor, home, home accessories, home accessories paris, home accessories Paris, chandelier, Chandelier, CHANDELIER, table, tables, armchair, lamps, lamp, wall mirrors, mirrors, wall lamps, lamps",
  };

  //BLOCKING ALL OTHER CATEGORIES EXCEPT PERFUMES
  if (category !== "perfumes")
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          minHeight: "60vh",
        }}
      >
        NOT FOUND
      </div>
    );

  return (
    <div className="catalogue_container">
      {/* METATAGS IF WE COMING FROM CATEGORY AND/OR SUBCATEGORY PAGE */}
      {!designer && category && (
        <Helmet>
          <title>1511 Paris | {category?.toUpperCase()}</title>
          {/* <link
            rel="canonical"
            href={
              category && !subCategory
                ? `https://1511.paris/${category}/`
                : `https://1511.paris/${category}/${subCategory}`
            }
          /> */}
          <meta
            name="keywords"
            content={
              keywordsPerCategory[category] ||
              `${category}, ${category} Online, ${category} Store, 1511 ${category}, 1511 ${category} Online, 1511 ${category} Store, Exclusive ${category}, Unnique ${category}`
            }
          />
          <meta
            name="description"
            content={`Discover the epitome of style and quality with our exquisite ${category} collection. Each product in this category showcases exceptional craftsmanship, attention to detail, and a perfect blend of fashion and functionality. From elegant apparel to sophisticated accessories, our ${category} range offers a curated selection of premium products designed to elevate your personal style. Whether you're looking for timeless classics or contemporary statement pieces, our ${category} collection has something to suit every taste and occasion. Immerse yourself in luxury and indulge in the finest ${category} products that exude elegance and make a lasting impression.`}
          />
          <meta
            property="og:description"
            content={`Discover the epitome of style and quality with our exquisite ${category} collection. Each product in this category showcases exceptional craftsmanship, attention to detail, and a perfect blend of fashion and functionality. From elegant apparel to sophisticated accessories, our ${category} range offers a curated selection of premium products designed to elevate your personal style. Whether you're looking for timeless classics or contemporary statement pieces, our ${category} collection has something to suit every taste and occasion. Immerse yourself in luxury and indulge in the finest ${category} products that exude elegance and make a lasting impression.`}
          ></meta>
        </Helmet>
      )}

      {/* BREAD CRUMBS */}
      <BreadCrumb
        category={designer ? "Designers" : category}
        subCategory={designer ? designer : subCategory}
      />
      {/* METATAGS IF WE COMING FROM DESIGNER PAGE */}
      {designer && locationState ? (
        <div className="catalogue_designer_container">
          <Helmet>
            <title>
              1511 Paris | {designer}: Where Artistry Meets Elegance
            </title>
            {/* <link
              rel="canonical"
              href={`https://1511.paris/designers/${designer}`}
            /> */}
            <meta
              name="keywords"
              content="1511 Paris Designers, 1511 Paris Designer Collections, designers, clothing designers, decor designers, Designer Collections, Fashion Labels, Design, Modern decor, Handcrafted, Handmade, Exclusive, Unique, Craftsmanship"
            />
            <meta
              name="description"
              content={`Welcome to the captivating world of ${designer}. Explore the unique artistic vision and impeccable craftsmanship of this renowned designer. Dive into a curated collection of decor, fashion, clothing, and perfume creations that embody elegance, style, and sophistication. Immerse yourself in the designer's distinctive aesthetic, where every piece tells a story of creativity and passion. From exquisite home decor accents to fashion-forward clothing and captivating fragrances, ${designer} offers a harmonious blend of luxury and innovation. Discover the perfect blend of artistry and functionality as you explore their products, each meticulously crafted to elevate your lifestyle. Step into ${designer}'s realm and experience the epitome of refined taste and timeless beauty.`}
            />
            <meta
              property="og:description"
              content={`Welcome to the captivating world of ${designer}. Explore the unique artistic vision and impeccable craftsmanship of this renowned designer. Dive into a curated collection of decor, fashion, clothing, and perfume creations that embody elegance, style, and sophistication. Immerse yourself in the designer's distinctive aesthetic, where every piece tells a story of creativity and passion. From exquisite home decor accents to fashion-forward clothing and captivating fragrances, ${designer} offers a harmonious blend of luxury and innovation. Discover the perfect blend of artistry and functionality as you explore their products, each meticulously crafted to elevate your lifestyle. Step into ${designer}'s realm and experience the epitome of refined taste and timeless beauty.`}
            ></meta>
          </Helmet>
          <h1 className="catalogue_designer_title">{designer}</h1>
          <div className="catalogue_designer_description_container">
            <p>
              {locationState?.designerInfo?.biography ===
              "Biography is required"
                ? null
                : locationState?.designerInfo?.biography}
            </p>
          </div>
        </div>
      ) : null}
      {/* CATEGORY TITLE */}
      <h1 style={{ textAlign: "center", margin: "2rem 0 1.8rem 0" }}>
        {category && !subCategory && category.toUpperCase()}
        {category && subCategory && subCategory.toUpperCase()}
      </h1>
      <div
        className="catalogue_page_wrapper"
        style={{
          paddingRight:
            width > 768 ? (category === "perfumes" ? "0" : "12rem") : "1rem",
        }}
      >
        {/* FILTERS */}
        {!isMobile && category !== "perfumes" ? (
          <Filters
            page={page}
            setPage={setPage}
            queryString={queryString}
            setQueryString={setQueryString}
            setFilteredProducts={setFilteredProducts}
          />
        ) : isMobile ? (
          <Modal
            page={page}
            setPage={setPage}
            queryString={queryString}
            setQueryString={setQueryString}
            setFilteredProducts={setFilteredProducts}
          />
        ) : null}
        {/* PRODUCTS */}

        {!isLoading || filteredProducts?.products?.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  filteredProducts?.products?.length && !isMobile
                    ? "repeat(3, 1fr)"
                    : isMobile
                    ? "repeat(1, 1fr)"
                    : "1fr",
              }}
            >
              {filteredProducts?.products?.map((product) => {
                if (product?.productActive) {
                  return <ProductCard key={product.id} item={product} />;
                }
              })}
            </div>
            {filteredProducts?.products?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  page={page}
                  onChange={(e, page) => setPage(page)}
                  count={Math.ceil(filteredProducts?.maxProducts / 30)}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="loading_container">
            <h2>
              <CircularProgress />
            </h2>
          </div>
        )}
        {filteredProducts?.products?.length === 0 && !isLoading && (
          <>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  marginRight: "2rem",
                }}
              >
                {"No products found"}
              </h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CataloguePage;
// products?.products?.length > 0 && !error ? ()
