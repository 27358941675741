import React from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import Home from "pages/Home";
import RequireAuth from "pages/RequireAuth";
import AdminPanel from "pages/AdminPanel";
import Dashboard from "components/AdminPanel/Dashboard";
import Users from "components/AdminPanel/Users";
import Products from "components/AdminPanel/Products";
import Orders from "components/AdminPanel/Orders";
import Categories from "components/AdminPanel/Categories";
import Designers from "components/AdminPanel/Designers";
import Login from "pages/auth/Login";
import Registration from "pages/auth/Registration";
import ForgotPassword from "pages/auth/ForgotPassword";
import AddProduct from "components/AdminPanel/AddProduct";
import Blogs from "components/AdminPanel/BlogPosts";
import BlogCategories from "components/AdminPanel/BlogCategories";
import Blog from "components/AdminPanel/Blog";
import ProductPage from "pages/ProductPage";
import CataloguePage from "pages/CataloguePage";
import ProfilePage from "pages/profile/ProfilePage";
import OrdersPage from "pages/profile/OrdersPage";
import AddressPage from "pages/profile/AddressPage";
import WhishListPage from "pages/profile/WishListPage";
import AccountDetailsPage from "pages/profile/AccountDetailsPage";
import ProfileNav from "pages/profile/ProfileNav";
import ShoppingBag from "pages/ShoppingBag";
import OrderValidation from "pages/OrderValidation";
import OrderDetails from "pages/profile/OrderDetails";
import CheckoutSuccess from "pages/profile/CheckoutSuccess";
import DesignersLanding from "pages/designers/landing";
import DesignersCatalogue from "pages/designers/designer";
import BlogsStory from "pages/blogs/blogs";
import BlogItem from "pages/blogs/blog";
import About from "pages/About";
import BlogCollage from "pages/BlogCollage";
import TermsOfService from "pages/terms/termsOfService";
import FAQ from "pages/terms/faq";
import TopBanner from "components/AdminPanel/TopBanner/TopBanner";
import SharedWishList from "components/WishList/SharedWishList";
import Merchandising from "components/AdminPanel/Merchandising/Merchandising";
import ReturnsPage from "pages/profile/ReturnsPage";
import Returns from "components/AdminPanel/Returns/Returns";
import HomeModules from "components/AdminPanel/HomeModules/HomeModules";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route element={<RequireAuth />}>
        {/* ADMINPANEL ROUTES */}
        <Route path="/adminpanel" element={<AdminPanel />}>
          <Route
            path="/adminpanel"
            element={<Navigate to="/adminpanel/dashboard" />}
          />
          <Route path="/adminpanel/dashboard" element={<Dashboard />} />
          <Route path="/adminpanel/users" element={<Users />} />
          <Route path="/adminpanel/products" element={<Products />} />
          <Route path="/adminpanel/products/add" element={<AddProduct />} />
          <Route
            path="/adminpanel/products/add/:productId"
            element={<AddProduct />}
          />
          <Route path="/adminpanel/orders" element={<Orders />} />
          <Route path="/adminpanel/returns" element={<Returns />} />
          <Route path="/adminpanel/categories" element={<Categories />} />
          <Route path="/adminpanel/designers" element={<Designers />} />
          <Route path="/adminpanel/blogs/posts" element={<Blogs />} />
          <Route path="/adminpanel/banners/top" element={<TopBanner />} />
          <Route path="/adminpanel/merchandising" element={<Merchandising />} />

          <Route
            path="/adminpanel/blogs/categories"
            element={<BlogCategories />}
          />
          <Route path="/adminpanel/blogs/:category/:id" element={<Blog />} />
          <Route path="/adminpanel/homemodules" element={<HomeModules />} />
        </Route>
        {/* PROFILE ROUTES */}
        <Route path="/profile" exact element={<ProfileNav />}>
          <Route
            path="/profile"
            exact
            element={<Navigate to="/profile/account" />}
          />
          <Route path="/profile/shopping-bag" element={<ShoppingBag />} />
          <Route
            path="/profile/order-validation"
            element={<OrderValidation />}
          />
          <Route path="/profile/account" exact element={<ProfilePage />} />
          <Route path="/profile/orders" exact element={<OrdersPage />} />
          <Route path="/profile/returns" exact element={<ReturnsPage />} />
          <Route path="/profile/address" exact element={<AddressPage />} />
          <Route path="/profile/wishList" exact element={<WhishListPage />} />
          <Route
            path="/profile/wishList/:id"
            exact
            element={<SharedWishList />}
          />
          <Route
            path="/profile/details"
            exact
            element={<AccountDetailsPage />}
          />
          <Route
            path="/profile/orders/:order_id"
            exact
            element={<OrderDetails />}
          />
        </Route>
      </Route>
      {/* GENERAL ROUTES */}
      {/*ROUTES BELOW TURNED OFF AS WE ONLY SELLING PERFUME*/}
      {/* <Route path="/designers/" element={<DesignersLanding />} /> */}
      {/* <Route path="/designers/:designer" element={<DesignersCatalogue />} />
      <Route
        path="/checoutSuccess/:order_id"
        exact
        element={<CheckoutSuccess />}
      /> */}
      {/* <Route path="/:category/:subCategory" element={<CataloguePage />} /> */}
      {/* <Route
        path="/product/:category/:subCategory/:product_id"
        element={<ProductPage />}
      /> */}
      <Route path="/product/:category/:product_id" element={<ProductPage />} />
      <Route path="/:category/" element={<CataloguePage />} />
      <Route path="/blogs" element={<BlogsStory />} />
      <Route path="/blogs/:category/:id" element={<BlogItem />} />
      <Route path="/about" element={<About />} />
      <Route path="/blog-collage/:id" element={<BlogCollage />} />

      {/* TERMS */}
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  );
};

export default Routers;
